select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

option:not(:checked) {
  color: black;
  /* prevent <option>s from becoming transparent as well */
}

.form-row {
  width: 100%;
  display: flex;
  
  .input:first-of-type {
    margin-right: 16px;
  }

  .input:last-of-type {
    margin-left: 16px;
  }

  .input:not(:first-of-type):not(:last-of-type) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.input {
  width: 100%;
  position: relative;
  margin-top: 32px;

  &__input {
    padding: 12px 30px;
    border: solid 1px $primaryColor;
    border-radius: 40px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    appearance: none;
    min-height: 45px;
    background-color: #fff;
  }

  &__label {
    color: #888;
    position: absolute;
    top: 13px;
    left: 30px;
    transition: 0.3s;
    cursor: text;
    user-select: none;
  }

  &__input:focus+&__label,
  &__input:not(:placeholder-shown)+&__label {
    top: -18px;
    left: 24px;
    color: $primaryColor;
    font-size: 16px;
  }

  &--select::after {
    @include pseudo-fontawesome;

    position: absolute;
    top: 17px;
    right: 24px;
    content: '\f078';
    pointer-events: none;
    font-size: 0.8em;
    color: #888;
  }

  &--textarea &__input {
    height: 300px;
    resize: vertical;
  }
}

.recap-wrapp {
  width: 100%;
  display: block;
  text-align: right;
  color: #aaa;
  font-size: 0.7rem;

  a {
    color: #4285F4;
    &:hover {
      text-decoration: underline;
    }
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}
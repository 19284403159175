.footer {
  background-color: $secondaryColor;
  min-height: 20vh;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding: 10px 20px;
  padding-top: 0;
  justify-items: center;

  @media #{$media-tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media #{$media-pc} {
    grid-template-columns: repeat(4, 1fr);
  }

  &::before {
    content: "";
    position: absolute;
    border-right: solid 98vw transparent;
    border-bottom: solid 50px $secondaryColor;
    top: -50px;
    left: 0;
    z-index: 11;

    @media #{$media-tablet} {
      border-bottom: solid 100px $secondaryColor;
      top: -100px;
    }
  }

  > * {
    position: relative;
    z-index: 12;
  }

  &__block {
    margin-bottom: 24px;
    width: 80%;
  }

  &__title {
    font-size: 32px;
    color: $primaryColor;
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px;

    &::before {
      content: "| ";
      color: #fff;
    }
  }

  &__text {
    color: #888;
    margin: 0;
    padding-left: 5px;
    font-size: 20px;

    a {
      color: #888;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 20px;

    a {
      color: #888;
      text-decoration: none;
      margin: 4px 0;
      position: relative;
      transition: 0.3s;

      &:hover {
        padding-left: 4px;
        color: #fff;
      }

      &::before {
        content: "/ ";
        color: $primaryColor;
      }
    }
  }

  &__credits {
    color: #fff;
    text-align: center;
    grid-column: 1/-1;
  }

  &__icons {
    display: flex;
    margin-bottom: 5px;
    font-size: 24px;

    a {
      color: #fff;
    }

    i {
      opacity: 0.65;
      margin: 0 16px;
      transition: 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__select {
    position: relative;
    width: 40%;
    margin-left: 12px;

    select {
      all: unset;
      width: 100%;
      display: block;
      color: #888;
      padding: 6px 12px;
      font-size: 1em;
      border: solid 1px #888;
      border-radius: 20px;
      margin-top: 14px;
      box-sizing: border-box;
    }

    &::before {
      @include pseudo-fontawesome;

      position: absolute;
      top: 10px;
      right: 14px;
      content: "\f078";
      pointer-events: none;
      font-size: 0.7em;
      color: $primaryColor;
    }
  }
}

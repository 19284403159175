.go-top-button {
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: 16px;
  right: 16px;
  background-color: $primaryColor;
  color: #fff;
  border-radius: 50%;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 6px $secondaryColor;
  z-index: 100;
  transition: 0.3s;
  cursor: pointer;

  &--hidden {
    bottom: -64px !important;
  }

  @media #{$media-tablet} {
    right: 32px;
    bottom: 32px;
  }

  &:hover {
    transform: translateY(3px);
  }

  &:active {
    transform: translateY(6px);
  }
}
.home-categories {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 250px;
  grid-gap: 50px;
  padding: 10px;

  @media #{$media-big-phone} {
    padding: 32px;
  }

  @media #{$media-tablet} {
    width: 80%;
    margin: 0 auto;
  }

  @media #{$media-pc} {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 300px;
  }

  @media #{$media-pc-wide} {
    grid-auto-rows: 320px;
  }

  &__item {
    background: url(../img/background.jpg) center;
    background-size: cover;
    position: relative;
    box-shadow:
      inset 1000px 1000px rgba($secondaryColor, 0.2),
      3px 3px 6px rgba(#000000, 0.2);
    font-size: 26px;

    @media #{$media-pc} {
      font-size: 36px;

      &--big {
        grid-row: span 2;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 120px;
      height: 100%;
      background-color: $secondaryColor;
      opacity: 0.8;
      transition: 0.3s;
    }

    &:hover::before {
      width: 140px;
    }

    span {
      display: block;
      position: absolute;
      bottom: 16px;
      margin-left: 16px;
      color: #fff;
    }
  }
}

.home-steps {
  background: url(../img/steps-bg.jpeg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 64px 16px;

  @media #{$media-pc} {
    justify-content: center;
    align-items: center;
    padding: 64px 10%;
  }

  @media #{$media-pc-wide} {
    padding: 64px 20%;
  }

  &__box {
    background-color: #fff;
    padding: 16px;
    width: 90%;
    min-height: 100px;
    margin: 16px 0;
    margin-right: 0;
    margin-left: auto;
    box-shadow: 5px 5px $primaryColor;

    @media #{$media-tablet} {
      width: 60%;
    }

    @media #{$media-pc} {
      width: 50%;
      margin-top: 0;
      margin-bottom: 0;
    }

    h3 {
      margin: 0;
      font-size: 40px;
      font-weight: 300;

      &::first-letter {
        color: $primaryColor;
      }
    }

    p {
      color: #888;
    }

    strong {
      color: $primaryColor;
    }

    &:nth-child(odd) {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

.contact-form {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media #{$media-big-phone} {
    width: 80%;
  }

  @media #{$media-tablet} {
    width: 70%;
  }

  @media #{$media-pc} {
    align-items: flex-start;
    width: 60%;
  }
}

.home-projects {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 0 32px;
  position: relative;
  z-index: 10;

  @media #{$media-pc} {
    padding: 32px 0 64px;
  }

  &__project {
    width: calc(100% - 50px);
    border-radius: 16px;
    box-shadow: 0 3px 3px rgba(#000000, 0.2);
    background-color: #fff;
    overflow: hidden;
    margin: 25px;
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    @media #{$media-big-phone} {
      width: 300px;
    }

    @media #{$media-pc} {
      max-width: 60%;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__project-overlay {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba($primaryColor, 0.5);
    color: #fff;
    font-weight: 300;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.2s;
    cursor: pointer;
    overflow: hidden;
    opacity: 0;
    text-align: center;
    font-size: 1.1em;

    h3 {
      font-weight: 200;
      margin: 0;
      position: relative;
      bottom: -16px;
      transition: 0.3s;
      // display: none;
    }

    hr {
      width: 10%;
      border: 0;
      transition: 0.3s;
      border-bottom: solid 1px #fff;
    }

    p {
      font-size: 0.8em;
      margin: 0;
      position: relative;
      top: -16px;
      transition: 0.3s;

      // display: none;
      img {
        height: 20px;
        opacity: 0.8;
      }
    }
  }

  &__project:hover &__project-overlay {
    opacity: 1;
    hr {
      width: 70%;
    }
    h3 {
      bottom: 0;
    }
    p {
      top: 0;
    }
    div {
      width: 90%;
      height: 90%;
    }
  }
}


.home-proj-lett {
  overflow: hidden;
  position: relative;

  &__letter {
    position: absolute;
    bottom: -50px;
    right: -80px;
    width: 100%;

    @media #{$media-tablet} {
      width: 400px;
      right: 0;
      bottom: -50px;
    }

    @media #{$media-tablet} {
      width: 500px;
      right: -50px;
      bottom: -100px;
    }
  }
}

.home-about {
  padding: 24px;
  position: relative;
  overflow: hidden;

  @media #{$media-tablet} {
    padding: 48px;
  }

  @media #{$media-pc} {
    padding: 80px 10%;
  }

  @media #{$media-pc-wide} {
    padding: 80px 20%;
  }

  &__content {
    text-align: left;
    position: relative;
    z-index: 10;

    h2 {
      color: #fff;
      font-size: 3.8em;
      text-align: left;
      margin: 0;
      margin-left: -14px;

      &::before {
        content: '| ';
        color: $primaryColor;
      }
    }

    p {
      font-size: 20px;
      color: #d1d1d1;
      width: 80%;

      @media #{$media-pc} {
        width: 600px;
      }
    }

    .btn {
      margin: 0 auto;

      @media #{$media-tablet} {
        margin: 0;
      }
    }
  }

  &__photos {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    opacity: 0.2;

    img {
      display: block;
      width: 120px; height: 120px;
    }

    @media #{$media-tablet} {
      flex-direction: row-reverse;
      flex-wrap: wrap;
      width: 400px;

      img {
        width: 200px; height: 200px;
      }
    }
  }

  &__rounded-photo {
    display: block;
    border-radius: 50%;
    width: 80%;
    margin: 0 auto;

    @media #{$media-tablet} {
      position: absolute;
      top: 50%;
      right: 10%;
      transform: translateY(-50%);
      width: 200px;
    }

    @media #{$media-pc} {
      width: 300px;
    }
  }

  &__signature {
    display: flex;
    flex-direction: column;
    span {
      color: #fff;
      font-size: 1.4em;
    }

    i {
      color: $primaryColor;
      font-size: 1.2em;
    }
  }

  &__letter{
    height: 280px;
    position: absolute;
    bottom: 60px;
    left: -40px;

    @media #{$media-tablet} {
      height: 400px;
      bottom: -45px;
    }

    @media #{$media-pc} {
      left: -60px;
      bottom: -20px;
    }
  }
}

.partners {
  width: 100%;
  overflow-y: auto;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 0;
  z-index: 10;

  @media #{$media-pc} {
    padding: 14px 0;
  }

  a {
    height: 30px;
    margin: 0 10px;

    img {
      height: 100%;
    }
  }

  @media #{$media-tablet} {
    a {
      margin: 0 50px;
    }
  }
}

.home-contact {
  position: relative;
  overflow: hidden;
  padding-bottom: 10vh;

  @media #{$media-pc} {
    padding-bottom: 20vh;
  }

  .contact-form {
    position: relative;
    z-index: 10;
  }

  &__letter {
    position: absolute;
    width: 270px;
    bottom: -10px;
    right: 0;

    @media #{$media-tablet} {
      width: 530px;
      right: -30px;
      bottom: 200px;
    }

    @media #{$media-pc} {
      width: 900px;
      right: 0;
      bottom: -150px;
    }
  }
}

.home-doing {
  position: relative;
  overflow: hidden;

  &__letter {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 80%;

    @media #{$media-tablet} {
      width: 500px;
      bottom: -200px;
    }

    @media #{$media-tablet} {
      width: 600px;
      bottom: -400px;
    }
  }
}

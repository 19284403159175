.shop-features {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shop-features-wrapper {
  width: calc(100% - 32px);
  margin: 0 auto;

  @media #{$media-tablet} {
    width: 80%;
  }

  @media #{$media-pc} {
    width: 70%;
  }
}

.wide-photo-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  box-shadow: 2px 2px 8px #aaa;
  margin: 32px 0;
  flex-direction: column;

  &:nth-of-type(even) {
    flex-direction: column-reverse;
  }

  @media #{$media-tablet} {
    flex-direction: row !important;
  }

  &__img {
    display: block;
    width: 100%;

    @media #{$media-tablet} {
      width: 50%;
      height: 100%;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;
    font-size: 1.1em;
    width: 100%;

    @media #{$media-tablet} {
      width: 50%;
      padding: 16px;
    }

    div {
      @media #{$media-tablet} {
        width: 80%;
      }
    }

    h3 {
      margin: 0;
      font-size: 1.6em;
      font-weight: 500;
      color: $primaryColor;
    }

    p {
      color: #333;
    }
  }
}

.shops-small-features {
  width: 80%;
  align-items: stretch;
  flex-direction: column;
  margin: 32px auto;

  @media #{$media-tablet} {
    flex-direction: row;
    width: 100%;
  }
}

.small-icon-section {
  background-color: #eee;
  box-shadow: 3px 3px 8px #aaa;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin: 32px 0;
  font-size: 1.1em;

  @media #{$media-tablet} {
    width: 25%;
    padding: 16px;
  }

  i {
    color: $primaryColor;
    font-size: 7em;
  }

  h3 {
    color: $primaryColor;
    font-size: 1.6em;
    font-weight: 500;
    margin: 24px 0;
  }

  p {
    margin: 0;
    color: #888;
  }
}
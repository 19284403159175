@font-face {
  font-family: 'Tw Cen MT';
  font-style: normal;
  font-weight: normal;
  src: local("Tw Cen MT"), url("../font/TwCenMT.woff") format("woff"); }

body {
  margin: 0;
  font-family: "Tw Cen MT";
  color: #333; }

a {
  color: #8F022C;
  text-decoration: none; }

::selection {
  background: #8F022C;
  color: #fff; }

.primary {
  color: #8F022C; }

section {
  padding: 24px 5px;
  min-height: 40vh;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative; }
  section.tall {
    min-height: 80vh; }
  section.gray {
    background-color: #f1f1f1; }
  section.dark {
    background-color: #15131A; }

h2 {
  font-size: 40px;
  color: #333;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 12px;
  font-weight: 500; }
  h2 i {
    font-style: normal;
    color: #8F022C; }

.heading-text {
  color: #888;
  font-size: 18px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 10; }
  @media (min-width: 800px) {
    .heading-text {
      width: 700px; } }

.floating-box {
  border-radius: 20px;
  box-shadow: 2px 2px 6px rgba(21, 19, 26, 0.2);
  background-color: #fff;
  padding: 16px; }
  .floating-box h2 {
    margin-top: 0; }
  .floating-box p {
    width: 100%; }

.space-4 {
  height: 4px; }

.space-5 {
  height: 5px; }

.space-6 {
  height: 6px; }

.space-7 {
  height: 7px; }

.space-8 {
  height: 8px; }

.space-9 {
  height: 9px; }

.space-10 {
  height: 10px; }

.space-11 {
  height: 11px; }

.space-12 {
  height: 12px; }

.space-13 {
  height: 13px; }

.space-14 {
  height: 14px; }

.space-15 {
  height: 15px; }

.space-16 {
  height: 16px; }

.space-17 {
  height: 17px; }

.space-18 {
  height: 18px; }

.space-19 {
  height: 19px; }

.space-20 {
  height: 20px; }

.space-21 {
  height: 21px; }

.space-22 {
  height: 22px; }

.space-23 {
  height: 23px; }

.space-24 {
  height: 24px; }

.space-25 {
  height: 25px; }

.space-26 {
  height: 26px; }

.space-27 {
  height: 27px; }

.space-28 {
  height: 28px; }

.space-29 {
  height: 29px; }

.space-30 {
  height: 30px; }

.space-31 {
  height: 31px; }

.space-32 {
  height: 32px; }

.space-33 {
  height: 33px; }

.space-34 {
  height: 34px; }

.space-35 {
  height: 35px; }

.space-36 {
  height: 36px; }

.space-37 {
  height: 37px; }

.space-38 {
  height: 38px; }

.space-39 {
  height: 39px; }

.space-40 {
  height: 40px; }

.space-41 {
  height: 41px; }

.space-42 {
  height: 42px; }

.space-43 {
  height: 43px; }

.space-44 {
  height: 44px; }

.space-45 {
  height: 45px; }

.space-46 {
  height: 46px; }

.space-47 {
  height: 47px; }

.space-48 {
  height: 48px; }

.space-49 {
  height: 49px; }

.space-50 {
  height: 50px; }

.space-51 {
  height: 51px; }

.space-52 {
  height: 52px; }

.space-53 {
  height: 53px; }

.space-54 {
  height: 54px; }

.space-55 {
  height: 55px; }

.space-56 {
  height: 56px; }

.space-57 {
  height: 57px; }

.space-58 {
  height: 58px; }

.space-59 {
  height: 59px; }

.space-60 {
  height: 60px; }

.flex, .alert, .alert__icon {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.hidden {
  display: none !important; }

/*! sanitize.css v8.0.0 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritance in all browsers (opinionated).
  */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
  * 1. Use the default cursor in all browsers (opinionated).
  * 2. Use the default user interface font in all browsers (opinionated).
  * 3. Correct the line height in all browsers.
  * 4. Use a 4-space tab width in all browsers (opinionated).
  * 5. Prevent adjustments of font size after orientation changes in
  *    IE on Windows Phone and in iOS.
  * 6. Breaks words to prevent overflow in all browsers (opinionated).
  */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
  * ========================================================================== */
/**
  * Remove the margin in all browsers (opinionated).
  */
body {
  margin: 0; }

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
  * ========================================================================== */
/**
  * 1. Add the correct sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
  * Add the correct display in IE.
  */
main {
  display: block; }

/**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */
nav ol,
nav ul {
  list-style: none; }

/**
  * 1. Use the default monospace user interface font
  *    in all browsers (opinionated).
  * 2. Correct the odd `em` font sizing in all browsers.
  */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
  * ========================================================================== */
/**
  * Remove the gray background on active links in IE 10.
  */
a {
  background-color: transparent; }

/**
  * Add the correct text decoration in Edge, IE, Opera, and Safari.
  */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
b,
strong {
  font-weight: bolder; }

/**
  * 1. Use the default monospace user interface font
  *    in all browsers (opinionated).
  * 2. Correct the odd `em` font sizing in all browsers.
  */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
  * Add the correct font size in all browsers.
  */
small {
  font-size: 80%; }

/*
  * Remove the text shadow on text selections in Firefox 61- (opinionated).
  * 1. Restore the coloring undone by defining the text shadow
  *    in all browsers (opinionated).
  */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
  * ========================================================================== */
/*
  * Change the alignment on media elements in all browers (opinionated).
  */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
  * Add the correct display in IE 9-.
  */
audio,
video {
  display: inline-block; }

/**
  * Add the correct display in iOS 4-7.
  */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
  * Remove the border on images inside links in IE 10-.
  */
img {
  border-style: none; }

/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */
svg:not([fill]) {
  fill: currentColor; }

/**
  * Hide the overflow in IE.
  */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
  * ========================================================================== */
/**
  * Collapse border spacing in all browsers (opinionated).
  */
table {
  border-collapse: collapse; }

/* Forms
  * ========================================================================== */
/**
  * Inherit styling in all browsers (opinionated).
  */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
  * Remove the margin in Safari.
  */
button,
input,
select {
  margin: 0; }

/**
  * 1. Show the overflow in IE.
  * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
  */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
  * Correct the padding in Firefox.
  */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
  * Show the overflow in Edge and IE.
  */
input {
  overflow: visible; }

/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
  * 1. Add the correct display in Edge and IE.
  * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
  * Remove the inheritance of text transform in Firefox.
  */
select {
  text-transform: none; }

/**
  * 1. Remove the margin in Firefox and Safari.
  * 2. Remove the default vertical scrollbar in IE.
  * 3. Change the resize direction on textareas in all browsers (opinionated).
  */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
  * Remove the padding in IE 10-.
  */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
  * Correct the cursor style of increment and decrement buttons in Safari.
  */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
  * Correct the text style of placeholders in Chrome, Edge, and Safari.
  */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
  * Remove the inner padding in Chrome and Safari on macOS.
  */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
  * Remove the inner border and padding of focus outlines in Firefox.
  */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
  * Restore the focus outline styles unset by the previous rule in Firefox.
  */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
  * ========================================================================== */
/*
  * Add the correct display in Edge and IE.
  */
details {
  display: block; }

/*
  * Add the correct styles in Edge, IE, and Safari.
  */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
  * Add the correct display in all browsers.
  */
summary {
  display: list-item; }

/* Scripting
  * ========================================================================== */
/**
  * Add the correct display in IE 9-.
  */
canvas {
  display: inline-block; }

/**
  * Add the correct display in IE.
  */
template {
  display: none; }

/* User interaction
  * ========================================================================== */
/*
  * 1. Remove the tapping delay in IE 10.
  * 2. Remove the tapping delay on clickable elements
       in all browsers (opinionated).
  */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
  * Add the correct display in IE 10-.
  */
[hidden] {
  display: none; }

/* Accessibility
  * ========================================================================== */
/**
  * Change the cursor on busy elements in all browsers (opinionated).
  */
[aria-busy="true"] {
  cursor: progress; }

/*
  * Change the cursor on control elements in all browsers (opinionated).
  */
[aria-controls] {
  cursor: pointer; }

/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements in all browsers (opinionated).
  */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
  * Change the display on visually hidden accessible elements
  * in all browsers (opinionated).
  */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

.navigation {
  width: 100%;
  padding: 0 32px;
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000; }
  .navigation__logo {
    width: 140px;
    filter: invert(1); }
    @media (min-width: 800px) {
      .navigation__logo {
        width: 224px;
        margin-left: 20px; } }
  .navigation__links {
    position: fixed;
    margin: 0;
    padding: 0;
    top: -120vh;
    left: 0;
    height: 100vh;
    width: 100%;
    background: linear-gradient(110deg, #8F022C, #DD3256);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
    z-index: 999; }
    @media (min-width: 800px) {
      .navigation__links {
        background: transparent;
        position: static;
        flex-direction: row;
        height: auto;
        width: auto; } }
    .navigation__links--active {
      top: 0; }
    .navigation__links a {
      display: block;
      padding: 18px 15px;
      color: #fff;
      font-size: 24px;
      text-decoration: none;
      position: relative;
      transition: 0.3s; }
      .navigation__links a:hover {
        transform: translateX(-3px); }
      @media (min-width: 800px) {
        .navigation__links a {
          font-size: 20px; } }
  .navigation__trigger {
    position: fixed;
    top: 48px;
    right: 32px;
    color: #fff;
    font-size: 2em;
    cursor: pointer;
    z-index: 101;
    border-radius: 50%;
    padding: 6px;
    transition: 0.3s;
    background-color: transparent;
    z-index: 1000; }
    .navigation__trigger--background {
      background: linear-gradient(110deg, #8F022C, #DD3256);
      box-shadow: 2px 2px 5px rgba(21, 19, 26, 0.6); }
      .navigation__trigger--background .burger {
        transform: scale(0.8); }
    @media (min-width: 800px) {
      .navigation__trigger {
        display: none; } }

.burger {
  width: 40px; }
  .burger span {
    display: block;
    height: 2px;
    background-color: #fff;
    margin: 8px 0;
    transform-origin: 50% 50%;
    transition: 0.3s;
    position: relative;
    opacity: 1; }
  .burger--active span:nth-of-type(1) {
    transform: rotate(-45deg) translateY(9px) translateX(-6px); }
  .burger--active span:nth-of-type(2) {
    opacity: 0; }
  .burger--active span:nth-of-type(3) {
    transform: rotate(45deg) translateY(-9px) translateX(-6px); }

.btn {
  all: unset;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
  width: 200px;
  height: 50px;
  border-radius: 94px;
  background-image: linear-gradient(260deg, #8f022c, #dd3256);
  cursor: pointer;
  position: relative;
  transition: 0.3s; }
  .btn--small {
    width: 160px;
    height: 40px;
    font-size: 18px; }
  @media (min-width: 800px) {
    .btn {
      font-size: 27px;
      width: 249px;
      height: 62px; }
      .btn--small {
        width: 190px;
        height: 50px;
        font-size: 24px; } }
  .btn:hover {
    transform: translateX(3px); }
  .btn:focus {
    border: dashed 2px #fff; }
  .btn:active {
    border: 0; }

.footer {
  background-color: #15131A;
  min-height: 20vh;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding: 10px 20px;
  padding-top: 0;
  justify-items: center; }
  @media (min-width: 800px) {
    .footer {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1300px) {
    .footer {
      grid-template-columns: repeat(4, 1fr); } }
  .footer::before {
    content: "";
    position: absolute;
    border-right: solid 98vw transparent;
    border-bottom: solid 50px #15131A;
    top: -50px;
    left: 0;
    z-index: 11; }
    @media (min-width: 800px) {
      .footer::before {
        border-bottom: solid 100px #15131A;
        top: -100px; } }
  .footer > * {
    position: relative;
    z-index: 12; }
  .footer__block {
    margin-bottom: 24px;
    width: 80%; }
  .footer__title {
    font-size: 32px;
    color: #8F022C;
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px; }
    .footer__title::before {
      content: "| ";
      color: #fff; }
  .footer__text {
    color: #888;
    margin: 0;
    padding-left: 5px;
    font-size: 20px; }
    .footer__text a {
      color: #888; }
  .footer__list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 20px; }
    .footer__list a {
      color: #888;
      text-decoration: none;
      margin: 4px 0;
      position: relative;
      transition: 0.3s; }
      .footer__list a:hover {
        padding-left: 4px;
        color: #fff; }
      .footer__list a::before {
        content: "/ ";
        color: #8F022C; }
  .footer__credits {
    color: #fff;
    text-align: center;
    grid-column: 1/-1; }
  .footer__icons {
    display: flex;
    margin-bottom: 5px;
    font-size: 24px; }
    .footer__icons a {
      color: #fff; }
    .footer__icons i {
      opacity: 0.65;
      margin: 0 16px;
      transition: 0.3s; }
      .footer__icons i:hover {
        opacity: 1; }
  .footer__select {
    position: relative;
    width: 40%;
    margin-left: 12px; }
    .footer__select select {
      all: unset;
      width: 100%;
      display: block;
      color: #888;
      padding: 6px 12px;
      font-size: 1em;
      border: solid 1px #888;
      border-radius: 20px;
      margin-top: 14px;
      box-sizing: border-box; }
    .footer__select::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      top: 10px;
      right: 14px;
      content: "\f078";
      pointer-events: none;
      font-size: 0.7em;
      color: #8F022C; }

.heroimage {
  height: 100vh;
  width: 100%;
  color: #fff;
  padding: 1px;
  position: relative;
  overflow: hidden; }
  .heroimage__bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .heroimage__bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    object-fit: cover; }
  .heroimage__bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 19, 26, 0.4); }
  .heroimage__content {
    margin: 0 20px;
    margin-top: 80px;
    position: relative;
    z-index: 10; }
    @media (min-width: 500px) {
      .heroimage__content {
        margin: 0 46px;
        margin-top: 100px; } }
    @media (min-width: 800px) {
      .heroimage__content {
        margin-left: 84px;
        margin-top: 20vh; } }
    @media (min-width: 1300px) {
      .heroimage__content {
        margin-left: 140px;
        width: 70%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0; } }
    .heroimage__content h1 {
      font-size: 46px;
      font-weight: 300;
      margin: 24px 0; }
      @media (min-width: 500px) {
        .heroimage__content h1 {
          font-size: 58px; } }
      @media (min-width: 800px) {
        .heroimage__content h1 {
          font-size: 80px;
          max-width: 80%; } }
      @media (min-width: 1300px) {
        .heroimage__content h1 {
          font-size: 100px;
          max-width: 80%; } }
    .heroimage__content p {
      color: #ddd;
      font-size: 18px;
      margin: 24px 0;
      max-width: 600px;
      margin-bottom: 32px; }
      @media (min-width: 500px) {
        .heroimage__content p {
          font-size: 24px; } }
      @media (min-width: 1300px) {
        .heroimage__content p {
          font-size: 27px; } }
  .heroimage__icons {
    position: absolute;
    bottom: 12px;
    right: 12px;
    display: flex;
    font-size: 24px; }
    .heroimage__icons a {
      color: #fff; }
    .heroimage__icons i {
      opacity: 0.75;
      margin: 0 16px;
      transition: 0.3s; }
      .heroimage__icons i:hover {
        opacity: 1; }

.go-top-button {
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: 16px;
  right: 16px;
  background-color: #8F022C;
  color: #fff;
  border-radius: 50%;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 6px #15131A;
  z-index: 100;
  transition: 0.3s;
  cursor: pointer; }
  .go-top-button--hidden {
    bottom: -64px !important; }
  @media (min-width: 800px) {
    .go-top-button {
      right: 32px;
      bottom: 32px; } }
  .go-top-button:hover {
    transform: translateY(3px); }
  .go-top-button:active {
    transform: translateY(6px); }

.loading {
  position: fixed;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  border-radius: 50%;
  box-shadow: 2px 2px 6px 0 rgba(153, 153, 153, 0.4);
  background-color: #fff;
  z-index: 2000;
  transition: 0.3s; }
  .loading--active {
    top: 50px; }
  .loading::after {
    content: '';
    display: block;
    padding: 8px;
    border-radius: 50%;
    border: solid 3px #8F022C;
    border-top: solid 3px transparent;
    animation: loading 1s ease-in-out infinite; }

@keyframes loading {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.alert {
  position: fixed;
  right: 50%;
  bottom: -200px;
  padding: 16px;
  width: 70%;
  transform: translateX(50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
  z-index: 1000;
  flex-direction: column;
  transition: bottom 0.3s; }
  .alert--active {
    bottom: 16px; }
  @media (min-width: 800px) {
    .alert {
      width: 300px;
      right: 16px;
      transform: none; } }
  .alert__icon {
    justify-content: center;
    background-color: #333;
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-bottom: 8px; }
  .alert span {
    color: #8F022C;
    font-size: 1.3em; }
  .alert--success .alert__icon {
    background-color: green; }
  .alert--failed .alert__icon {
    background-color: firebrick; }
  .alert--warn .alert__icon {
    background-color: #ff8800; }

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }

option:not(:checked) {
  color: black;
  /* prevent <option>s from becoming transparent as well */ }

.form-row {
  width: 100%;
  display: flex; }
  .form-row .input:first-of-type {
    margin-right: 16px; }
  .form-row .input:last-of-type {
    margin-left: 16px; }
  .form-row .input:not(:first-of-type):not(:last-of-type) {
    margin-left: 16px;
    margin-right: 16px; }

.input {
  width: 100%;
  position: relative;
  margin-top: 32px; }
  .input__input {
    padding: 12px 30px;
    border: solid 1px #8F022C;
    border-radius: 40px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    appearance: none;
    min-height: 45px;
    background-color: #fff; }
  .input__label {
    color: #888;
    position: absolute;
    top: 13px;
    left: 30px;
    transition: 0.3s;
    cursor: text;
    user-select: none; }
  .input__input:focus + .input__label,
  .input__input:not(:placeholder-shown) + .input__label {
    top: -18px;
    left: 24px;
    color: #8F022C;
    font-size: 16px; }
  .input--select::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: 17px;
    right: 24px;
    content: '\f078';
    pointer-events: none;
    font-size: 0.8em;
    color: #888; }
  .input--textarea .input__input {
    height: 300px;
    resize: vertical; }

.recap-wrapp {
  width: 100%;
  display: block;
  text-align: right;
  color: #aaa;
  font-size: 0.7rem; }
  .recap-wrapp a {
    color: #4285F4; }
    .recap-wrapp a:hover {
      text-decoration: underline; }

.grecaptcha-badge {
  visibility: hidden; }

.home-categories {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 250px;
  grid-gap: 50px;
  padding: 10px; }
  @media (min-width: 500px) {
    .home-categories {
      padding: 32px; } }
  @media (min-width: 800px) {
    .home-categories {
      width: 80%;
      margin: 0 auto; } }
  @media (min-width: 1300px) {
    .home-categories {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 300px; } }
  @media (min-width: 1600px) {
    .home-categories {
      grid-auto-rows: 320px; } }
  .home-categories__item {
    background: url(../img/background.jpg) center;
    background-size: cover;
    position: relative;
    box-shadow: inset 1000px 1000px rgba(21, 19, 26, 0.2), 3px 3px 6px rgba(0, 0, 0, 0.2);
    font-size: 26px; }
    @media (min-width: 1300px) {
      .home-categories__item {
        font-size: 36px; }
        .home-categories__item--big {
          grid-row: span 2; } }
    .home-categories__item::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 120px;
      height: 100%;
      background-color: #15131A;
      opacity: 0.8;
      transition: 0.3s; }
    .home-categories__item:hover::before {
      width: 140px; }
    .home-categories__item span {
      display: block;
      position: absolute;
      bottom: 16px;
      margin-left: 16px;
      color: #fff; }

.home-steps {
  background: url(../img/steps-bg.jpeg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 64px 16px; }
  @media (min-width: 1300px) {
    .home-steps {
      justify-content: center;
      align-items: center;
      padding: 64px 10%; } }
  @media (min-width: 1600px) {
    .home-steps {
      padding: 64px 20%; } }
  .home-steps__box {
    background-color: #fff;
    padding: 16px;
    width: 90%;
    min-height: 100px;
    margin: 16px 0;
    margin-right: 0;
    margin-left: auto;
    box-shadow: 5px 5px #8F022C; }
    @media (min-width: 800px) {
      .home-steps__box {
        width: 60%; } }
    @media (min-width: 1300px) {
      .home-steps__box {
        width: 50%;
        margin-top: 0;
        margin-bottom: 0; } }
    .home-steps__box h3 {
      margin: 0;
      font-size: 40px;
      font-weight: 300; }
      .home-steps__box h3::first-letter {
        color: #8F022C; }
    .home-steps__box p {
      color: #888; }
    .home-steps__box strong {
      color: #8F022C; }
    .home-steps__box:nth-child(odd) {
      margin-right: auto;
      margin-left: 0; }

.contact-form {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 500px) {
    .contact-form {
      width: 80%; } }
  @media (min-width: 800px) {
    .contact-form {
      width: 70%; } }
  @media (min-width: 1300px) {
    .contact-form {
      align-items: flex-start;
      width: 60%; } }

.home-projects {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 0 32px;
  position: relative;
  z-index: 10; }
  @media (min-width: 1300px) {
    .home-projects {
      padding: 32px 0 64px; } }
  .home-projects__project {
    width: calc(100% - 50px);
    border-radius: 16px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: hidden;
    margin: 25px;
    position: relative; }
    .home-projects__project::before {
      content: "";
      display: block;
      padding-top: 100%; }
    @media (min-width: 500px) {
      .home-projects__project {
        width: 300px; } }
    @media (min-width: 1300px) {
      .home-projects__project {
        max-width: 60%; } }
    .home-projects__project img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .home-projects__project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(143, 2, 44, 0.5);
    color: #fff;
    font-weight: 300;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.2s;
    cursor: pointer;
    overflow: hidden;
    opacity: 0;
    text-align: center;
    font-size: 1.1em; }
    .home-projects__project-overlay h3 {
      font-weight: 200;
      margin: 0;
      position: relative;
      bottom: -16px;
      transition: 0.3s; }
    .home-projects__project-overlay hr {
      width: 10%;
      border: 0;
      transition: 0.3s;
      border-bottom: solid 1px #fff; }
    .home-projects__project-overlay p {
      font-size: 0.8em;
      margin: 0;
      position: relative;
      top: -16px;
      transition: 0.3s; }
      .home-projects__project-overlay p img {
        height: 20px;
        opacity: 0.8; }
  .home-projects__project:hover .home-projects__project-overlay {
    opacity: 1; }
    .home-projects__project:hover .home-projects__project-overlay hr {
      width: 70%; }
    .home-projects__project:hover .home-projects__project-overlay h3 {
      bottom: 0; }
    .home-projects__project:hover .home-projects__project-overlay p {
      top: 0; }
    .home-projects__project:hover .home-projects__project-overlay div {
      width: 90%;
      height: 90%; }

.home-proj-lett {
  overflow: hidden;
  position: relative; }
  .home-proj-lett__letter {
    position: absolute;
    bottom: -50px;
    right: -80px;
    width: 100%; }
    @media (min-width: 800px) {
      .home-proj-lett__letter {
        width: 400px;
        right: 0;
        bottom: -50px; } }
    @media (min-width: 800px) {
      .home-proj-lett__letter {
        width: 500px;
        right: -50px;
        bottom: -100px; } }

.home-about {
  padding: 24px;
  position: relative;
  overflow: hidden; }
  @media (min-width: 800px) {
    .home-about {
      padding: 48px; } }
  @media (min-width: 1300px) {
    .home-about {
      padding: 80px 10%; } }
  @media (min-width: 1600px) {
    .home-about {
      padding: 80px 20%; } }
  .home-about__content {
    text-align: left;
    position: relative;
    z-index: 10; }
    .home-about__content h2 {
      color: #fff;
      font-size: 3.8em;
      text-align: left;
      margin: 0;
      margin-left: -14px; }
      .home-about__content h2::before {
        content: '| ';
        color: #8F022C; }
    .home-about__content p {
      font-size: 20px;
      color: #d1d1d1;
      width: 80%; }
      @media (min-width: 1300px) {
        .home-about__content p {
          width: 600px; } }
    .home-about__content .btn {
      margin: 0 auto; }
      @media (min-width: 800px) {
        .home-about__content .btn {
          margin: 0; } }
  .home-about__photos {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    opacity: 0.2; }
    .home-about__photos img {
      display: block;
      width: 120px;
      height: 120px; }
    @media (min-width: 800px) {
      .home-about__photos {
        flex-direction: row-reverse;
        flex-wrap: wrap;
        width: 400px; }
        .home-about__photos img {
          width: 200px;
          height: 200px; } }
  .home-about__rounded-photo {
    display: block;
    border-radius: 50%;
    width: 80%;
    margin: 0 auto; }
    @media (min-width: 800px) {
      .home-about__rounded-photo {
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        width: 200px; } }
    @media (min-width: 1300px) {
      .home-about__rounded-photo {
        width: 300px; } }
  .home-about__signature {
    display: flex;
    flex-direction: column; }
    .home-about__signature span {
      color: #fff;
      font-size: 1.4em; }
    .home-about__signature i {
      color: #8F022C;
      font-size: 1.2em; }
  .home-about__letter {
    height: 280px;
    position: absolute;
    bottom: 60px;
    left: -40px; }
    @media (min-width: 800px) {
      .home-about__letter {
        height: 400px;
        bottom: -45px; } }
    @media (min-width: 1300px) {
      .home-about__letter {
        left: -60px;
        bottom: -20px; } }

.partners {
  width: 100%;
  overflow-y: auto;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 0;
  z-index: 10; }
  @media (min-width: 1300px) {
    .partners {
      padding: 14px 0; } }
  .partners a {
    height: 30px;
    margin: 0 10px; }
    .partners a img {
      height: 100%; }
  @media (min-width: 800px) {
    .partners a {
      margin: 0 50px; } }

.home-contact {
  position: relative;
  overflow: hidden;
  padding-bottom: 10vh; }
  @media (min-width: 1300px) {
    .home-contact {
      padding-bottom: 20vh; } }
  .home-contact .contact-form {
    position: relative;
    z-index: 10; }
  .home-contact__letter {
    position: absolute;
    width: 270px;
    bottom: -10px;
    right: 0; }
    @media (min-width: 800px) {
      .home-contact__letter {
        width: 530px;
        right: -30px;
        bottom: 200px; } }
    @media (min-width: 1300px) {
      .home-contact__letter {
        width: 900px;
        right: 0;
        bottom: -150px; } }

.home-doing {
  position: relative;
  overflow: hidden; }
  .home-doing__letter {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 80%; }
    @media (min-width: 800px) {
      .home-doing__letter {
        width: 500px;
        bottom: -200px; } }
    @media (min-width: 800px) {
      .home-doing__letter {
        width: 600px;
        bottom: -400px; } }

.acat-heading {
  color: #fff; }
  .acat-heading i {
    color: #29AF7C; }

.acat-details {
  background-color: #F1F1F1;
  margin: 48px;
  margin-top: 84px;
  margin-bottom: 64px;
  padding: 16px;
  position: relative;
  min-height: 400px; }
  @media (min-width: 800px) {
    .acat-details {
      display: flex;
      padding: 64px 16px;
      justify-content: space-around;
      align-items: center; } }
  @media (min-width: 1300px) {
    .acat-details {
      padding: 110px 16px;
      width: 1200px;
      margin: 64px auto 48px; } }
  .acat-details::before, .acat-details::after {
    content: '';
    background-color: #29AF7C;
    position: absolute;
    width: 250px;
    height: 250px;
    z-index: -1; }
  .acat-details::before {
    top: -48px;
    left: -48px; }
  .acat-details::after {
    bottom: -48px;
    right: -48px; }
  .acat-details .acat-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    margin: 16px 0; }
    .acat-details .acat-detail h3 {
      color: #8F022C;
      font-size: 30px;
      margin: 0;
      font-weight: 300; }
      @media (min-width: 1300px) {
        .acat-details .acat-detail h3 {
          font-size: 40px; } }
    .acat-details .acat-detail > i {
      font-size: 8em;
      color: #8F022C;
      margin: 12px 0; }
      @media (min-width: 1300px) {
        .acat-details .acat-detail > i {
          margin: 32px 0; } }
    .acat-details .acat-detail p {
      color: #15131A;
      margin: 0;
      text-align: center; }
      @media (min-width: 1300px) {
        .acat-details .acat-detail p {
          max-width: 260px; } }

.acat-floating-app {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 90%; }
  @media (min-width: 1300px) {
    .acat-floating-app {
      padding: 32px;
      right: 30%;
      max-width: 40%; } }

.acat-competition-grid {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px; }
  @media (min-width: 800px) {
    .acat-competition-grid {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1300px) {
    .acat-competition-grid {
      grid-template-columns: 1fr 1fr 1fr;
      width: 1200px;
      margin: 0 auto; } }

.competition {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  .competition i {
    color: #DFBA42;
    font-size: 1.8em; }
  .competition h4 {
    color: #8F022C;
    font-weight: 300;
    font-size: 30px;
    margin: 12px 0; }
    @media (min-width: 800px) {
      .competition h4 {
        font-size: 35px; } }
  .competition small {
    color: #888888; }

.acat-section-logo {
  width: 80vw;
  height: 500px;
  position: absolute;
  left: -100px;
  bottom: -20px; }
  @media (min-width: 1300px) {
    .acat-section-logo {
      left: -200px;
      bottom: -100px;
      width: 700px;
      height: auto; } }

.acat-software-img {
  height: 60vh;
  margin: 64px 100px;
  position: relative; }
  @media (min-width: 1300px) {
    .acat-software-img {
      transform: translateX(400px); } }
  @media (min-width: 1600px) {
    .acat-software-img {
      height: 70vh;
      transform: translateX(600px); } }

.acat-prints {
  padding-bottom: 0; }
  .acat-prints__img {
    display: block;
    margin: 0 auto;
    width: 95%;
    margin-top: 24px; }
    @media (min-width: 800px) {
      .acat-prints__img {
        width: 80%; } }
    @media (min-width: 1300px) {
      .acat-prints__img {
        width: auto; } }

.systems-features-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 32px; }
  @media (min-width: 800px) {
    .systems-features-wrapper {
      width: 90%; } }
  @media (min-width: 1300px) {
    .systems-features-wrapper {
      width: 80%;
      margin-bottom: 64px; } }
  @media (min-width: 1600px) {
    .systems-features-wrapper {
      width: 70%; } }

.smaller-img-section {
  width: 100%;
  margin: 32px auto -48px auto;
  position: relative; }
  @media (min-width: 500px) {
    .smaller-img-section {
      width: 90%; } }
  .smaller-img-section__img {
    width: 100%;
    box-sizing: border-box;
    margin: 32px 0;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16); }
    @media (min-width: 800px) {
      .smaller-img-section__img {
        width: 60%; } }
  .smaller-img-section__content {
    background-color: #f1f1f1;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    padding: 16px;
    min-height: 30%;
    text-align: center; }
    .smaller-img-section__content h3 {
      font-weight: 500;
      margin: 0;
      font-size: 1.7em; }
    .smaller-img-section__content p {
      color: #888; }
    @media (min-width: 800px) {
      .smaller-img-section__content {
        position: absolute;
        width: 60%;
        top: 50%;
        left: 40%;
        width: 60%;
        max-height: 80%;
        padding: 48px;
        box-sizing: border-box;
        transform: translateY(-50%); }
        .smaller-img-section__content h3 {
          font-size: 2.3em; }
        .smaller-img-section__content p {
          font-size: 1.2em; } }
    .smaller-img-section__content::before {
      content: '';
      position: absolute;
      border: dashed 1px #8F022C;
      top: 8px;
      left: 8px;
      width: calc(100% - 16px);
      height: calc(100% - 16px); }
      @media (min-width: 1300px) {
        .smaller-img-section__content::before {
          top: 16px;
          left: 16px;
          width: calc(100% - 32px);
          height: calc(100% - 32px); } }
  @media (min-width: 800px) {
    .smaller-img-section:nth-of-type(odd) .smaller-img-section__img {
      margin-left: 40%; }
    .smaller-img-section:nth-of-type(odd) .smaller-img-section__content {
      left: 0; } }

.systems-sign-inside {
  display: none; }
  @media (min-width: 800px) {
    .systems-sign-inside {
      display: flex; } }

.systems-sign-outside {
  text-align: center;
  margin-top: 16px; }
  @media (min-width: 800px) {
    .systems-sign-outside {
      display: none; } }

.systems-projects-wrapper {
  width: 100%;
  padding: 10px;
  margin: 0 auto; }
  @media (min-width: 1300px) {
    .systems-projects-wrapper {
      width: 90%; } }
  @media (min-width: 1600px) {
    .systems-projects-wrapper {
      width: 80%; } }

.systems-project {
  display: flex;
  flex-direction: column-reverse;
  background: #fff;
  border: solid 3px #8F022C;
  padding: 16px 0;
  margin: 24px 0;
  box-sizing: border-box; }
  @media (min-width: 800px) {
    .systems-project {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 32px 16px; }
      .systems-project:nth-of-type(even) {
        flex-direction: row-reverse; } }
  .systems-project__img {
    width: calc(100% - 32px);
    margin: 0 16px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16); }
    @media (min-width: 800px) {
      .systems-project__img {
        width: 55%;
        height: 100%; } }
  .systems-project__content {
    padding: 16px; }
    @media (min-width: 800px) {
      .systems-project__content {
        width: 45%;
        padding: 32px; } }
    .systems-project__content h3 {
      font-weight: 500;
      font-size: 2.1em;
      margin: 0;
      color: #15131A; }
      .systems-project__content h3 i {
        font-style: normal;
        color: #8F022C; }
    .systems-project__content p {
      color: #333; }
      @media (min-width: 800px) {
        .systems-project__content p {
          font-size: 1.2em; } }
    .systems-project__content strong {
      padding: 2px;
      font-style: italic;
      color: #8F022C;
      background-color: rgba(143, 2, 44, 0.2);
      font-weight: 300; }

.shop-features {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.shop-features-wrapper {
  width: calc(100% - 32px);
  margin: 0 auto; }
  @media (min-width: 800px) {
    .shop-features-wrapper {
      width: 80%; } }
  @media (min-width: 1300px) {
    .shop-features-wrapper {
      width: 70%; } }

.wide-photo-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  box-shadow: 2px 2px 8px #aaa;
  margin: 32px 0;
  flex-direction: column; }
  .wide-photo-section:nth-of-type(even) {
    flex-direction: column-reverse; }
  @media (min-width: 800px) {
    .wide-photo-section {
      flex-direction: row !important; } }
  .wide-photo-section__img {
    display: block;
    width: 100%; }
    @media (min-width: 800px) {
      .wide-photo-section__img {
        width: 50%;
        height: 100%; } }
  .wide-photo-section__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;
    font-size: 1.1em;
    width: 100%; }
    @media (min-width: 800px) {
      .wide-photo-section__content {
        width: 50%;
        padding: 16px; } }
    @media (min-width: 800px) {
      .wide-photo-section__content div {
        width: 80%; } }
    .wide-photo-section__content h3 {
      margin: 0;
      font-size: 1.6em;
      font-weight: 500;
      color: #8F022C; }
    .wide-photo-section__content p {
      color: #333; }

.shops-small-features {
  width: 80%;
  align-items: stretch;
  flex-direction: column;
  margin: 32px auto; }
  @media (min-width: 800px) {
    .shops-small-features {
      flex-direction: row;
      width: 100%; } }

.small-icon-section {
  background-color: #eee;
  box-shadow: 3px 3px 8px #aaa;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin: 32px 0;
  font-size: 1.1em; }
  @media (min-width: 800px) {
    .small-icon-section {
      width: 25%;
      padding: 16px; } }
  .small-icon-section i {
    color: #8F022C;
    font-size: 7em; }
  .small-icon-section h3 {
    color: #8F022C;
    font-size: 1.6em;
    font-weight: 500;
    margin: 24px 0; }
  .small-icon-section p {
    margin: 0;
    color: #888; }

.page404 {
  --glitch-width: 100vw;
  --glitch-height: 100vh;
  --gap-horizontal: 20px;
  --gap-vertical: 10px;
  --time-anim: 4s;
  --delay-anim: 2s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: none;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: transparent;
  --blend-color-5: #af4949;
  /* Glitch styles */
  /* Animations */
  /* Flash */ }
  .page404 .glitchy__title,
  .page404 .glitchy__text,
  .page404 .navigation {
    animation-name: glitch-anim-text;
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite; }
  .page404 .navigation__trigger {
    display: none; }
  .page404 .glitchy__title {
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.2); }
  .page404 .glitchy__text,
  .page404 .navigation {
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.25); }
  .page404 .glitch {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--glitch-width);
    height: var(--glitch-height);
    overflow: hidden; }
  .page404 .glitch__img {
    position: absolute;
    top: calc(-1 * var(--gap-vertical));
    left: calc(-1 * var(--gap-horizontal));
    width: calc(100% + var(--gap-horizontal) * 2);
    height: calc(100% + var(--gap-vertical) * 2);
    background: url(../img/img1.jpg) no-repeat 50% 0;
    background-color: var(--blend-color-1);
    background-size: cover;
    transform: translate3d(0, 0, 0);
    background-blend-mode: var(--blend-mode-1); }
  .page404 .glitch__img:nth-child(n+2) {
    opacity: 0; }
  .page404 .glitch__img:nth-child(n+2) {
    animation-duration: var(--time-anim);
    animation-delay: var(--delay-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite; }
  .page404 .glitch__img:nth-child(2) {
    background-color: var(--blend-color-2);
    background-blend-mode: var(--blend-mode-2);
    animation-name: glitch-anim-1; }
  .page404 .glitch__img:nth-child(3) {
    background-color: var(--blend-color-3);
    background-blend-mode: var(--blend-mode-3);
    animation-name: glitch-anim-2; }
  .page404 .glitch__img:nth-child(4) {
    background-color: var(--blend-color-4);
    background-blend-mode: var(--blend-mode-4);
    animation-name: glitch-anim-3; }
  .page404 .glitch__img:nth-child(5) {
    background-color: var(--blend-color-5);
    background-blend-mode: var(--blend-mode-5);
    animation-name: glitch-anim-flash; }

@keyframes glitch-anim-1 {
  0% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%); }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%); }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%); }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%); }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%); }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%); }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%); }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%); }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%); }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%); }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%); }
  21.9% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0); }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-2 {
  0% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%); }
  3% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%); }
  5% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%); }
  7% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }
  9% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%); }
  11% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%); }
  13% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }
  15% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%); }
  17% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%); }
  19% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%); }
  20% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%); }
  21.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0); }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-3 {
  0% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%); }
  1.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%); }
  2% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%); }
  2.5% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%); }
  3% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%); }
  5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%); }
  5.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%); }
  7% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%); }
  8% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%); }
  9% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%); }
  10.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%); }
  11% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%); }
  13% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%); }
  14% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%); }
  14.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%); }
  15% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%); }
  16% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%); }
  18% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%); }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%); }
  21.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1); }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0); } }

@keyframes glitch-anim-text {
  0% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%); }
  2% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%); }
  4% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%); }
  5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%); }
  6% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%); }
  7% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%); }
  8% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%); }
  9% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%); }
  9.9% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1); }
  10%, 100% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

@keyframes glitch-anim-flash {
  0%, 5% {
    opacity: 0.2;
    transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0); }
  5.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0); } }

.main-wrapper--exit {
  animation: transition-exit 300ms ease-in-out; }

.main-wrapper--enter {
  animation: transition-enter 300ms ease-in-out; }

@keyframes transition-exit {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes transition-enter {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.btn {
  all: unset;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
  width: 200px;
  height: 50px;
  border-radius: 94px;
  background-image: linear-gradient(260deg, #8f022c, #dd3256);
  cursor: pointer;
  position: relative;
  transition: 0.3s;
  // border: dotted 2px transparent;

  &--small {
    width: 160px;
    height: 40px;
    font-size: 18px;
  }

  @media #{$media-tablet} {
    font-size: 27px;
    width: 249px;
    height: 62px;

    
    &--small {
      width: 190px;
      height: 50px;
      font-size: 24px;
    }
  }

  &:hover {
    transform: translateX(3px);
  }

  &:focus {
    border: dashed 2px #fff;
  }

  &:active {
    border: 0;
  }
}
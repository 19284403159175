.loading {
  position: fixed;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  border-radius: 50%;
  box-shadow: 2px 2px 6px 0 rgba(153, 153, 153, 0.4);
  background-color: #fff;
  z-index: 2000;
  transition: 0.3s;

  &--active {
    top: 50px;
  }

  &::after {
    content: '';
    display: block;
    padding: 8px;
    border-radius: 50%;
    border: solid 3px $primaryColor;
    border-top: solid 3px transparent;
    animation: loading 1s ease-in-out infinite;
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

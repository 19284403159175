.alert {
  @extend .flex;
  position: fixed;
  right: 50%;
  bottom: -200px;
  padding: 16px;
  width: 70%;
  transform: translateX(50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 3px 3px 8px rgba(#000, 0.16);
  z-index: 1000;
  flex-direction: column;
  transition: bottom 0.3s;

  &--active {
    bottom: 16px;
  }

  @media #{$media-tablet} {
    width: 300px;
    right: 16px;
    transform: none;
  }

  &__icon {
    @extend .flex;
    justify-content: center;
    background-color: #333;
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-bottom: 8px;
  }

  span {
    color: $primaryColor;
    font-size: 1.3em;
  }

  &--success &__icon {
    background-color: green;
  }

  &--failed &__icon {
    background-color: firebrick;
  }

  &--warn &__icon {
    background-color: #ff8800;
  }
}
.navigation {
  width: 100%;
  padding: 0 32px;
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000;

  &__logo {
    width: 140px;
    filter: invert(1);

    @media #{$media-tablet} {
      width: 224px;
      margin-left: 20px;
    }
  }

  &__links {
    position: fixed;
    margin: 0;
    padding: 0;
    top: -120vh;
    left: 0;
    height: 100vh;
    width: 100%;
    background: $gradient;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
    z-index: 999;
    
    @media #{$media-tablet} {
      background: transparent;
      position: static;
      flex-direction: row;
      height: auto;
      width: auto;
    }

    &--active {
      top: 0;
    }

    a {
      display: block;
      padding: 18px 15px;
      color: #fff;
      font-size: 24px;
      text-decoration: none;
      position: relative;
      transition: 0.3s;

      &:hover {
        transform: translateX(-3px);
      }

      @media #{$media-tablet} {
        font-size: 20px;
      }
    }
  }

  &__trigger {
    position: fixed;
    top: 48px;
    right: 32px;
    color: #fff;
    font-size: 2em;
    cursor: pointer;
    z-index: 101;
    border-radius: 50%;
    padding: 6px;
    transition: 0.3s;
    background-color: transparent;
    z-index: 1000;

    &--background {
      background: $gradient;
      box-shadow: 2px 2px 5px rgba($secondaryColor, 0.6);

      .burger {
        transform: scale(0.8);
      }
    }

    @media #{$media-tablet} {
      display: none;
    }
  }
}

.burger {
  width: 40px;

  span {
    display: block;
    height: 2px;
    background-color: #fff;
    margin: 8px 0;
    transform-origin: 50% 50%;
    transition: 0.3s;
    position: relative;
    opacity: 1;
  }

  &--active {
    span:nth-of-type(1) {
      transform: rotate(-45deg) translateY(9px) translateX(-6px);
    }
    
    span:nth-of-type(2) {
      opacity: 0;
    }
    
    span:nth-of-type(3) {
      transform: rotate(45deg) translateY(-9px) translateX(-6px);
    }
  }
}
body {
  margin: 0;
  font-family: $default-font;
  color: #333;
}

a {
  color: $primaryColor;
  text-decoration: none;
}

::selection {
  background: $primaryColor;
  color: #fff;
}

.primary {
  color: $primaryColor;
}

section {
  padding: 24px 5px;
  min-height: 40vh;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;

  &.tall {
    min-height: 80vh;
  }

  &.gray {
    background-color: #f1f1f1;
  }

  &.dark {
    background-color: $secondaryColor;
  }
}

h2 {
  font-size: 40px;
  color: #333;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 12px;
  font-weight: 500;

  i {
    font-style: normal;
    color: $primaryColor;
  }
}

.heading-text {
  color: #888;
  font-size: 18px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 10;

  @media #{$media-tablet} {
    width: 700px;
  }
}

.floating-box {
  border-radius: 20px;
  box-shadow: 2px 2px 6px rgba($secondaryColor, 0.2);
  background-color: #fff;
  padding: 16px;

  h2 {
    margin-top: 0;
  }

  p {
    width: 100%;
  }
}

@for $i from 4 through 60 {
  .space-#{$i} {
    height: #{$i}px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hidden {
  display: none !important;
}

.main-wrapper {
  // transition: opacity 450ms ease;
  // animation-fill-mode: forwards;
  // opacity: 1;

  &--exit {
    animation: transition-exit 300ms ease-in-out;
    // opacity: 0;
  }

  &--enter {
    animation: transition-enter 300ms ease-in-out;
    // opacity: 1;
  }
}

@keyframes transition-exit {
  0% {
    opacity: 1;
    // transform: translateY(0)
  }

  100% {
    opacity: 0;
    // transform: translateY(-100px);
  }
}

@keyframes transition-enter {
  0% {
    opacity: 0;
    // transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    // transform: translateY(0)
  }
}
.systems-features-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 32px;

  @media #{$media-tablet} {
    width: 90%;
  }

  @media #{$media-pc} {
    width: 80%;
    margin-bottom: 64px;
  }

  @media #{$media-pc-wide} {
    width: 70%;
  }
}

.smaller-img-section {
  width: 100%;
  margin: 32px auto -48px auto;
  position: relative;

  
  @media #{$media-big-phone} {
    width: 90%;
  }

  @media #{$media-tablet} {
    // width: 100%;
  }

  &__img {
    // display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 32px 0;
    box-shadow: 3px 3px 6px rgba(#000, 0.16);

    @media #{$media-tablet} {
      width: 60%;
    }
  }

  &__content {
    background-color: #f1f1f1;
    box-shadow: 3px 3px 6px rgba(#000, 0.16);
    position: relative;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    padding: 16px;
    min-height: 30%;
    text-align: center;

    h3 {
      font-weight: 500;
      margin: 0;
      font-size: 1.7em;
    }

    p {
      color: #888;
    }

    @media #{$media-tablet} {
      position: absolute;
      width: 60%;
      top: 50%;
      left: 40%;
      width: 60%;
      max-height: 80%;
      padding: 48px;
      box-sizing: border-box;
      transform: translateY(-50%);

      h3 {
        font-size: 2.3em;
      }

      p {
        font-size: 1.2em;
      }
    }

    &::before {
      content: '';
      position: absolute;
      border: dashed 1px $primaryColor;
      top: 8px;
      left: 8px;
      width: calc(100% - 16px);
      height: calc(100% - 16px);

      @media #{$media-pc} {
        top: 16px;
        left: 16px;
        width: calc(100% - 32px);
        height: calc(100% - 32px);
      }
    }
  }

  @media #{$media-tablet} {
    &:nth-of-type(odd) &__img {
      margin-left: 40%;
    }

    &:nth-of-type(odd) &__content {
      left: 0;
    }
  }
}

.systems-sign-inside {
  display: none;

  @media #{$media-tablet} {
    display: flex;
  }
}

.systems-sign-outside {
  text-align: center;
  margin-top: 16px;

  @media #{$media-tablet} {
    display: none;
  }
}

.systems-projects-wrapper {
  width: 100%;
  padding: 10px;
  margin: 0 auto;

  @media #{$media-pc} {
    width: 90%;
  }

  @media #{$media-pc-wide} {
    width: 80%;
  }
}

.systems-project {
  display: flex;
  flex-direction: column-reverse;
  background: #fff;
  border: solid 3px $primaryColor;
  padding: 16px 0;
  margin: 24px 0;
  box-sizing: border-box;

  @media #{$media-tablet} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 16px;

    &:nth-of-type(even) {
      flex-direction: row-reverse;
    }
  }

  &__img {
    width: calc(100% - 32px);
    margin: 0 16px;
    box-shadow: 2px 2px 8px rgba(#000, 0.16);

    @media #{$media-tablet} {
      width: 55%;
      height: 100%;
    }
  }

  &__content {
    padding: 16px;
    
    @media #{$media-tablet} {
      width: 45%;
      padding: 32px;
    }

    h3 {
      font-weight: 500;
      font-size: 2.1em;
      margin: 0;
      color: $secondaryColor;

      i {
        font-style: normal;
        color: $primaryColor;
      }
    }

    p {
      color: #333;

      @media #{$media-tablet} {
        font-size: 1.2em;
      }
    }

    strong {
      padding: 2px;
      font-style: italic;
      color: $primaryColor;
      background-color: rgba($primaryColor, 0.2);
      font-weight: 300;
    }
  }
}
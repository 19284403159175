.heroimage {
  height: 100vh;
  width: 100%;
  color: #fff;
  padding: 1px;
  position: relative;
  overflow: hidden;

  &__bg-image {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
  }

  &__bg-video {
    position: absolute;
    top: 50%; left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%; min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    object-fit: cover;
  }

  &__bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($secondaryColor, 0.4);
  }

  &__content {
    margin: 0 20px;
    margin-top: 80px;
    position: relative;
    z-index: 10;

    @media #{$media-big-phone} {
      margin: 0 46px;
      margin-top: 100px;
    }

    @media #{$media-tablet} {
      margin-left: 84px;
      margin-top: 20vh;
    }

    @media #{$media-pc} {
      margin-left: 140px;
      width: 70%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }

    h1 {
      font-size: 46px;
      font-weight: 300;
      margin: 24px 0;

      @media #{$media-big-phone} {
        font-size: 58px;
      }

      @media #{$media-tablet} {
        font-size: 80px;
        max-width: 80%;
      }

      @media #{$media-pc} {
        font-size: 100px;
        max-width: 80%;
      }
    }

    p {
      color: #ddd;
      font-size: 18px;
      margin: 24px 0;
      max-width: 600px;
      margin-bottom: 32px;

      @media #{$media-big-phone} {
        font-size: 24px;
      }

      @media #{$media-pc} {
        font-size: 27px;
      }
    }
  }

  &__icons {
    position: absolute;
    bottom: 12px;
    right: 12px;
    display: flex;
    font-size: 24px;

    a {
      color: #fff;
    }

    i {
      opacity: 0.75;
      margin: 0 16px;
      transition: 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
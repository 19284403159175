$primaryColor: #8F022C;
$secondaryColor: #15131A;

$gradient: linear-gradient(110deg, $primaryColor, #DD3256);

$media-big-phone: screen and (min-width: 500px);
$media-tablet: screen and (min-width: 800px);
$media-pc: screen and (min-width: 1300px);
$media-pc-wide: screen and (min-width: 1600px);

@font-face {
  font-family: 'Tw Cen MT';
  font-style: normal;
  font-weight: normal;
  src: local('Tw Cen MT'), url('../font/TwCenMT.woff') format('woff');
}

$default-font: 'Tw Cen MT';

@mixin pseudo-fontawesome {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

$acatColor: #29AF7C;

.acat-heading {
  color: #fff;

  i {
    color: $acatColor;
  }
} 

.acat-details {
  background-color: #F1F1F1;
  margin: 48px;
  margin-top: 84px;
  margin-bottom: 64px;
  padding: 16px;
  position: relative;
  min-height: 400px;

  @media #{$media-tablet} {
    display: flex;
    padding: 64px 16px;
    justify-content: space-around;
    align-items: center;
  }

  @media #{$media-pc} {
    padding: 110px 16px;
    width: 1200px;
    margin: 64px auto 48px;
  }

  &::before,
  &::after {
    content: '';
    background-color: $acatColor;
    position: absolute;
    width: 250px;
    height: 250px;
    z-index: -1;
  }

  &::before {
    top: -48px;
    left: -48px;
  }

  &::after {
    bottom: -48px;
    right: -48px;
  }

  .acat-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    margin: 16px 0;

    h3 {
      color: $primaryColor;
      font-size: 30px;
      margin: 0;
      font-weight: 300;

      @media #{$media-pc} {
        font-size: 40px;
      }
    }

    >i {
      font-size: 8em;
      color: $primaryColor;
      margin: 12px 0;

      @media #{$media-pc} {
        margin: 32px 0;
      }
    }

    p {
      color: $secondaryColor;
      margin: 0;
      text-align: center;

      @media #{$media-pc} {
        max-width: 260px;
      }
    }
  }
}

.acat-floating-app {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 90%;

  @media #{$media-pc} {
    padding: 32px;
    right: 30%;
    max-width: 40%;
  }
}

.acat-competition-grid {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media #{$media-tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media #{$media-pc} {
    grid-template-columns: 1fr 1fr 1fr;
    width: 1200px;
    margin: 0 auto;
  }
}

.competition {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 3px 3px 6px rgba(#000000, 0.16);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  i {
    color: #DFBA42;
    font-size: 1.8em;
  }

  h4 {
    color: $primaryColor;
    font-weight: 300;
    font-size: 30px;
    margin: 12px 0;

    @media #{$media-tablet} {
      font-size: 35px;
    }
  }

  small {
    color: #888888;
  }
}

.acat-section-logo {
  width: 80vw;
  height: 500px;
  position: absolute;
  left: -100px;
  bottom: -20px;

  @media #{$media-pc} {
    left: -200px;
    bottom: -100px;
    width: 700px;
    height: auto;
  }
}

.acat-software-img {
  height: 60vh;
  margin: 64px 100px;
  position: relative;

  @media #{$media-pc} {
    transform: translateX(400px);
  }

  @media #{$media-pc-wide} {
    height: 70vh;
    transform: translateX(600px);
  }
}

.acat-prints {
  padding-bottom: 0;

  &__img {
    display: block;
    margin: 0 auto;
    width: 95%;
    margin-top: 24px;

    @media #{$media-tablet} {
      width: 80%;
    }

    @media #{$media-pc} {
      width: auto;
    }
  }
}